import React from "react"
import { Link as ScrollLink } from "react-scroll"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"

import Layout from "@components/layout"
import HeroNarc from "@components/heroNarc"
import Container from "@components/container"
import { Heading1, Heading2, purpleButton } from "@styles/ui"
import Anchor from "@components/anchor"
import Section from "@components/section"
import Table from "@components/table"
import BorderedContent from "@components/borderedContent"
import References from "@components/references"
import Seo from "@components/seo"

const EfficacyAndSafety = ({ data, location }) => {
  const heroImg = withArtDirection(getImage(data.heroDesktop), [
    {
      media: "(max-width: 767px)",
      image: getImage(data.heroMobile),
    },
  ])

  const tableRow1Style = tw`py-4 text-base leading-[20px] px-4 border-alto md:(px-10) xl:(px-20 h-[112px])`
  const listUlStyles = tw`list-disc py-2 padding-inline-start[30px] marker:(text-[12px]) md:(py-0) xl:(padding-inline-start[30px]) `
  const listLiStyles = tw`py-1 md:(py-0)`
  const tableColStyle = tw`font-semibold sm:(mb-2) md:(pr-8 col-span-5)`
  const gridStyle = tw`md:(justify-center items-center grid grid-cols-12)`
  const tableCol2Style = tw`md:(col-span-7)`

  const table1Data = {
    theme: "narcolepsy",
    header: (
      <>
        <tr>
          <th
            scope="col"
            tw="font-bold w-[30%] text-xl leading-[130%] py-[30px] px-4 border-white rounded-t-[30px] md:(text-base text-center font-medium px-10 w-[50%]) xl:(px-20 rounded-t-[45px])"
          >
            Study 1 was a phase 3, double&#8209;blind, placebo&#8209;controlled,
            multicenter, randomized&#8209;withdrawal study<sup>1,2</sup>
          </th>
        </tr>
      </>
    ),
    body: (
      <>
        <tr tw="bg-white">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>Robust clinical trial</div>
              <div css={tableCol2Style}>
                Phase 3, double&#8209;blind, placebo&#8209;controlled,
                multicenter, randomized&#8209;withdrawal study
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-alabaster">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>Evaluated in adults of all ages</div>
              <div css={tableCol2Style}>
                201 patients aged 18-70 (median age, 36 years) with:
                <ul css={listUlStyles}>
                  <li css={listLiStyles}>Narcolepsy with cataplexy</li>
                  <li css={listLiStyles}>
                    A baseline history of at least 14 cataplexy attacks in a
                    typical <nobr>2-week</nobr> period prior to any treatment
                    for narcolepsy symptoms
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-white">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>
                Treatment background
                <br />
                (at study entry)
              </div>
              <div css={tableCol2Style}>
                Included patients who were taking:
                <ul css={listUlStyles}>
                  <li css={listLiStyles}>
                    XYREM<sup>&reg;</sup> (sodium oxybate) oral solution only,
                  </li>
                  <li css={listLiStyles}>XYREM + another anticataplectic,</li>
                  <li css={listLiStyles}>
                    A non&#8209;XYREM anticataplectic, or
                  </li>
                  <li css={listLiStyles}>
                    Were cataplexy&#8209;treatment naïve
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-alabaster">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>
                Up to 9 months (280 days) of exposure to XYWAV in safety
                population
              </div>
              <div css={tableCol2Style}>
                Included titration, stable&#8209;dose period,
                randomized&#8209;withdrawal period, and optional
                open&#8209;label extension
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-white">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>Dosing regimens</div>
              <div css={tableCol2Style}>
                Patients took either 2 equally divided doses or 2 unequal doses,
                depending on individual patient needs
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-alabaster">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>Primary efficacy endpoint</div>
              <div css={tableCol2Style}>
                Change in frequency of cataplexy attacks from the end of the
                stable&#8209;dose period to the end of the
                randomized&#8209;withdrawal period
              </div>
            </div>
          </td>
        </tr>
        <tr tw="bg-white">
          <td css={tableRow1Style}>
            <div css={gridStyle}>
              <div css={tableColStyle}>Secondary efficacy endpoint</div>
              <div css={tableCol2Style}>
                Change in Epworth Sleepiness Scale (ESS) score from the end of
                the stable&#8209;dose period to the end of the
                randomized&#8209;withdrawal period
              </div>
            </div>
          </td>
        </tr>
      </>
    ),
  }

  const gradientBorderPoints = [
    {
      icon: "/images/seven-plus-icon.svg",
      text: (
        <>
          The safety and effectiveness of XYWAV for the treatment of cataplexy
          or EDS in pediatric patients 7 years of age and older with narcolepsy
          have been established<sup>1</sup>
        </>
      ),
    },
    {
      icon: "/images/wavelength-icon.svg",
      text: (
        <>
          Use of XYWAV in pediatric patients 7 years of age and older with
          narcolepsy is supported by evidence from an adequate and
          well&#8209;controlled study of sodium oxybate in pediatric patients 7
          to 17 years of age, a study in adults showing a treatment effect of
          XYWAV similar to that observed with sodium oxybate, pharmacokinetic
          data of sodium oxybate from adult and pediatric patients, and
          pharmacokinetic data of XYWAV from healthy adult volunteers
          <sup>1</sup>
        </>
      ),
    },
    {
      icon: "/images/pediatric-patient-icon.svg",
      text: (
        <>
          XYWAV has not been studied in a pediatric clinical trial
          <sup>1</sup>
        </>
      ),
    },
  ]

  const referenceData = [
    <>
      XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
      oxybates). Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals,
      Inc.
    </>,
    <>
      Bogan RK, Thorpy MJ, Dauvilliers Y, et al. Efficacy and safety of calcium,
      magnesium, potassium, and sodium oxybates (lower-sodium oxybate [LXB];
      JZP-258) in a placebo-controlled, double-blind, randomized withdrawal
      study in adults with narcolepsy with cataplexy. <i>Sleep</i>. Published
      online October 14, 2020. doi.org/10.1093/sleep/zsaa206.
    </>,
    <>
      Dauvilliers Y, Foldvary-Schaefer N, Bogan RK, et al. Cataplexy-free days
      in a phase 3, placebo-controlled, double-blind,
      randomized&#8209;withdrawal study of calcium, magnesium, potassium, and
      sodium oxybates in adults with narcolepsy with cataplexy. Poster presented
      at: APSS SLEEP 2020 Virtual Meeting; August 27-30, 2020.
    </>,
  ]

  return (
    <Layout location={location}>
      <Seo
        title="XYWAV® Clinical Trials & Efficacy in Narcolepsy | XYWAV HCP "
        description="Read about the efficacy of XYWAV® (calcium, magnesium, potassium, and sodium oxybates) as a treatment for cataplexy and EDS in adult narcolepsy patients. See XYWAV oral solution full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse. "
        location={location}
      />
      <div>
        <div tw="gradient8">
          <HeroNarc
            eyebrowText="Efficacy for Cataplexy & EDS"
            bgColour="efficacyBlue"
            imgSrc={heroImg}
            altText="A patient working on her laptop."
            mobileHeight="65"
          >
            <h1 css={[Heading1, tw`md:(mr-[100px]) xl:(mr-0) max-w-[550px]`]}>
              Study 1 demonstrated{" "}
              <strong>efficacy for the treatment of cataplexy and EDS</strong>{" "}
              in adult patients with narcolepsy
              <sup>1</sup>
            </h1>

            <p tw="mt-5 mb-5 md:(mr-[100px]) xl:(mr-0 max-w-[450px] mt-[30px])">
              Study 1 was a phase 3, placebo-controlled, double-blind,
              randomized&#8209;withdrawal study<sup tw="text-[9.6px]">1,2</sup>
            </p>

            <p tw="sm:(mb-5) md:(mb-0)">
              <ScrollLink
                to="study-design"
                href="#study-design"
                smooth={true}
                duration={100}
                tw="underline text-coral-red font-semibold sm:(pb-[10px])"
              >
                Full Study Design
              </ScrollLink>
            </p>
          </HeroNarc>
        </div>

        <Section addedStyles={tw`gradient8 pt-6 xl:pb-[60px]`}>
          <Container>
            <h2 css={[Heading2, tw`mb-5 xl:(mb-[15px])`]}>
              XYWAV was demonstrated to have an impact on both cataplexy and
              excessive daytime sleepiness (EDS)
            </h2>
            <p tw="mb-4 xl:(max-w-[1100px])">
              Patients randomized to discontinue stable doses of XYWAV and take
              placebo during the double-blind, randomized&#8209;withdrawal
              period experienced significant worsening in symptoms
            </p>
            <picture>
              <source
                srcSet="/images/clinical-trial-chart.svg"
                media="(min-width: 768px)"
              />
              <img
                src="/images/clinical-trial-chart-mobile.svg"
                alt="Clinical trial chart showing the change in the number of cataplexy attacks per week for xywav patients versus a placebo."
                tw="w-full"
              />
            </picture>
          </Container>
        </Section>
        <Section addedStyles={tw`pt-6 xl:pb-[60px]`}>
          <Container>
            <h2 css={[Heading2, tw`font-normal mb-5 xl:(mb-[30px])`]}>
              <strong>Post hoc analysis: Cataplexy-free days per week</strong>{" "}
              during the double-blind, randomized&#8209;withdrawal period
              <sup>3</sup>
            </h2>
            <p>
              A post hoc analysis evaluated cataplexy&#8209;free days/week based
              on daily patient cataplexy frequency diaries completed from the
              beginning of the open&#8209;label titration period through the end
              of the double&#8209;blind, randomized&#8209;withdrawal period of
              Study 1.
              <sup>3</sup> This analysis was exploratory and not powered to
              determine statistical significance.
            </p>

            <p tw="font-semibold py-5 uppercase xl:(py-[30px])">
              cataplexy-free days per week during the double-blind,
              randomized&#8209;withdrawal period* (efficacy population
              <sup>&dagger;</sup>)<sup>3</sup>
            </p>

            <picture>
              <source
                srcSet="/images/cataplexy-free-days-chart.svg"
                media="(min-width: 768px)"
              />
              <img
                src="/images/cataplexy-free-days-chart-mobile.svg"
                alt="Chart showing the number of cataplexy-free days during a double-blind, randomized period."
                tw="w-full"
              />
            </picture>

            <p tw="pt-4 text-[12px] text-indent[-4px] pl-1">
              <sup>*</sup>Cataplexy-free days per week were calculated from
              daily diaries completed by participants for the primary efficacy
              analysis during each study period. Weekly cataplexy-free days were
              calculated using diary data as (# days with 0 cataplexy
              attacks)/(# days with diary data) x 7.
            </p>
            <p tw="text-[12px] text-indent[-4px] pl-1">
              <sup>&dagger;</sup>Defined as participants who took ≥1 dose of
              double&#8209;blind study drug and had ≥1{" "}
              <span tw="whitespace-nowrap">post-randomization</span> efficacy
              assessment.
            </p>

            <p tw="py-5">
              <strong>Limitations:</strong> Results based on post hoc analysis
              of average cataplexy&#8209;free days per week (0-7) from patient
              reports in daily cataplexy diaries throughout the main part of
              Study 1. Limitations include that this was an exploratory post hoc
              analysis, and as such, statistical comparisons were not performed.
              Any missing diary data are assumed to be missing completely at
              random, and the number of days without cataplexy attacks is
              treated as a continuous variable.
            </p>

            <Anchor
              link="/narcolepsy/safety-and-tolerability/"
              addedStyles={[
                purpleButton,
                tw`h-[60px] mt-6 mx-auto md:(p-5 max-w-[420px] h-[50px] ) xl:mt-[30px]`,
              ]}
            >
              Safety &amp; Tolerability of XYWAV
            </Anchor>
          </Container>
        </Section>
        <Section
          addedStyles={tw`gradient8 pt-6 xl:pb-[60px]`}
          sectionId="study-design"
        >
          <Container>
            <Table data={table1Data} />

            <picture>
              <source
                srcSet="/images/study-1-overview.svg"
                media="(min-width: 768px)"
              />
              <img
                src="/images/study-1-overview-mobile.svg"
                alt="Chart showing 67 patients who completed the double-blind randomized withdrawal study returned optimal dose of XYWAV. "
                tw="mt-5 w-full"
              />
            </picture>
          </Container>
        </Section>
        <Section addedStyles={tw`pt-8 xl:pb-[10px]`}>
          <Container>
            <h2 css={[Heading2, tw`mb-5 xl:(mb-[30px])`]}>
              <strong>
                XYWAV is approved in pediatric patients as young as 7 years of
                age
              </strong>
              <sup>1</sup>
            </h2>

            <div tw="xl:(flex column-gap[30px])">
              {gradientBorderPoints &&
                gradientBorderPoints.map((item, i) => (
                  <div key={i} tw="flex-1 text-center mt-[30px] xl:(mt-0)">
                    <BorderedContent
                      narcolepsy
                      thinBorder
                      addedStyles={tw`flex flex-col items-center py-10 min-h-[200px] xl:(min-h-[410px])`}
                    >
                      <img
                        src={item.icon}
                        alt=""
                        tw="w-[70px] h-[70px] md:(w-[90px] h-[90px])"
                      />
                      <p tw="mt-[35px] xl:(mt-[20px] max-w-[91%])">
                        {item.text}
                      </p>
                    </BorderedContent>
                  </div>
                ))}
            </div>

            <Anchor
              link="/narcolepsy/transitioning-from-xyrem/"
              addedStyles={[
                purpleButton,
                tw`h-[60px] mt-6 mx-auto md:(p-5 max-w-[470px] h-[50px] ) md:(w-[100%]) xl:mt-[60px]`,
              ]}
            >
              Transitioning from XYREM to XYWAV
            </Anchor>
          </Container>
        </Section>
      </div>
      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default EfficacyAndSafety

// EfficacyAndSafety.propTypes = {
//   location: PropTypes.object.isRequired,
// }

export const pageQuery = graphql`
  query EfficacyQuery {
    heroMobile: file(
      relativePath: {
        eq: "efficacy-and-safety-page/woman-working-laptop-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroDesktop: file(
      relativePath: { eq: "efficacy-and-safety-page/woman-working-laptop.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
